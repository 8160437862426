import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const MerchPage = () => (
  <Layout pageInfo={{ pageName: "merch" }}>
    <SEO title="Merch" />
    <h3>[stay tuned...]</h3>
  </Layout>
)

export default MerchPage
